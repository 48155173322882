import React, { useState } from "react";
import Layout from "../../components/Layout";
import "../../styles/contact.scss";
import { Link } from "gatsby";
import { useEffect } from "react";
import axios from "axios";
import { apiRoutes } from "../../constants/apiRoutes";
import { appendImage, createImage, createMarkup } from "../../utils";
import { isMobile } from "react-device-detect";
import Helmet from "../../components/Helmet";
import routes from "../../components/constants/routes";
import { ImageConstants } from "../../components/constants/Imageconstants";

export default function Contact() {
  const [contactdata, setContactdata] = useState();
  const contactusdata = async () => {
    try {
      const constactres = await axios.get(apiRoutes.Contactus);
      setContactdata(constactres.data.data.attributes);
    } catch (error) {}
  };
  useEffect(() => {
    contactusdata();
  }, []);

  const formHeightWeb = contactdata?.formHeightWeb ?? "100";
  const formHeightMob = contactdata?.formHeightMob ?? "80";

  useEffect(() => {
    appendImage("rich-editor");
  }, [contactdata]);

  const seoData = {
    title:
      "Contact Festival Events Asbl | Get in Touch for Cultural Experiences",
    description:
      "Contact Festival Events Asbl for inquiries, collaborations, or event details. Reach out to us and be part of Luxembourg's vibrant arts and entertainment scene.",
    url: `${process.env.GATSBY_SITE_URL}${routes.contact}/`,
  };

  return (
    <Layout NoHelmet={true}>
      <>
        <Helmet
          title={seoData.title}
          description={seoData.description}
          url={seoData?.url}
        />
        <section className="contact-hero-section">
          <h2>Contact</h2>
        </section>
        <section className="contact mt-30">
          <div className="container">
            <div className="row justify-content-center">
              {contactdata?.how_to_contact.map((item) => {
                return (
                  <div className="col-lg-3 col-md-6">
                    <div className="contact-box">
                      <img
                        src={createImage(item?.image.data.attributes.url)}
                        alt={item?.image_alt_text}
                        width={"auto"}
                        height={"auto"}
                      />
                      <div className="ml-10" key={item.id}>
                        <h4>{item.title}</h4>
                        <div
                          className="rich-editor"
                          dangerouslySetInnerHTML={createMarkup(
                            item.description
                          )}
                        ></div>
                        {/* <p className="email">
                          <Link to="mailto:info@festivalevents.lu">
                            info@festivalevents.lu
                          </Link>
                        </p> */}
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <div className="col-lg-3 col-md-6">
                <div className="contact-box">
                  <img src="../location.svg" />
                  <div className="ml-10">
                    <h4>Where to find us</h4>
                    <p>
                      c/o Embassy of Ireland 28 Route d'Arlon L-1140 Luxembourg
                      LUXEMBOURG
                    </p>
                    <p className="telephone">tel: 661-250007 (m)</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <div className="film-register mt-40">
          <iframe
            id="JotFormIFrame-232740555428458"
            title="Competition Entry Form"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            src={contactdata?.contactform_code}
            frameborder="0"
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              height: isMobile ? `${formHeightMob}vh` : `${formHeightWeb}vh`,
              border: "none",
            }}
            scrolling="no"
          ></iframe>
        </div>

        <section className="location">
          <h2>{contactdata?.locations?.title}</h2>
          <div className="">
            <div className="container screening-location">
              <div className="row">
                {contactdata?.locations?.location_details.map((item) => {
                  return (
                    <div className="col-md-4" key={item.id}>
                      <div className="">
                        <img
                          src={createImage(item?.image.data.attributes.url)}
                          alt={item?.location_image_alt_text}
                          width={"auto"}
                          height={"auto"}
                        />
                        <div
                          className="rich-editor"
                          dangerouslySetInnerHTML={createMarkup(item.address)}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="location-bg">
                <img
                  src={ImageConstants.screenlocation}
                  alt="festivalimage"
                  width={"auto"}
                  height={"auto"}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    </Layout>
  );
}
